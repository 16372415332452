.page.about {
    #main-content {
        position: relative;
        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
        }
    }
    .hero {
        .illustration {
            &.mobile {
                display: block;
                margin: 2.5rem auto 1rem;
            }
            @include media-breakpoint-up(lg) {
                &.mobile {
                    display: none;
                }
                &.desktop {
                    background: url(/img/illustration/cape.svg) no-repeat center;
                    background-size: contain;
                }
            }
        }
        .h1 {
            margin-bottom: 1rem;
            @include media-breakpoint-up(lg) {
                margin-bottom: 2.5rem;
            }
        }
        .main-point {
            font-weight: $font-weight-medium;
        }
        .supporting-points {
            font-size: 0.8rem;
        }
    }
    .features {
        margin-top: 4.5rem;
        .h2 {
            margin-bottom: 3rem;
        }
        img {
            width: 10rem;
            height: 10rem;
            margin-bottom: 1.4rem;
        }
    }
    .call-to-action {
        margin-top: 4.5rem;
        background: $cl_surface;
        padding-top: 2.5rem;
        padding-bottom: 3rem;
        .contact-illustration {
            @include media-breakpoint-up(md) {
                max-width: 12.85rem;
                position: relative;
                margin-left: auto;
                margin-right: auto;
            }
            .background {
                width: 100%;
                position: relative;
            }
            .foreground {
                position: absolute;
                top: 3.28%;
                left: 10.48%;
                height: 82.48%;
                margin: 0 auto;
            }
        }
        .h2 {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            max-width: 17.5rem;
        }
        .cta {
            display: block;
            @include media-breakpoint-up(md) {
                max-width: 15.35rem;
            }
        }
    }
}