form {
    .hidden {
        position: absolute;
        left: -5000px;
        max-width: 1px;
        max-height: 1px;
        margin: -1px;
    }
}

fieldset {
    position: relative;
}

button {
    -webkit-appearance: none;
    border: 0;
    @include media-breakpoint-up(md) {
        max-width: 15.35rem;
    }
}

input {
    position: relative;
    margin: 0 0 1.75rem;
    padding: 0 0 0.2rem;
    border: 0;
    border-bottom: solid 0.1rem $cl_on-background;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    outline: 0;
}

.error-message {
    visibility: hidden;
    position: absolute;
    top: 1.75rem;
}

label {
    display: block;
    opacity: 0;
    margin-top: -0.7rem;
    margin-bottom: 0.1rem;
}

.has-error {
    input {
        border-bottom-color: $cl_error;
        background: url(/img/icons/warning.svg) no-repeat right center;
        &::placeholder {
            color: $cl_error;
        }
    }
    .error-message {
        visibility: visible;
    }
}

.has-value,
.has-focus {
    label {
        opacity: 1;
    }
    input::placeholder {
        color: transparent;
    }
}