$cl_black: #000000;
$cl_white: #ffffff;
$cl_purple: #9521e3;
$cl_light-purple: rgb(251, 247, 255); // #f1defd;
$cl_red: #ff3527;
$cl_light-gray: #f1f1f2;

$cl_background: $cl_white;
$cl_primary: $cl_purple;
$cl_surface: $cl_light-purple;
$cl_error: $cl_red;
$cl_on-background: $cl_black;
$cl_on-primary: $cl_white;
$cl_on-surface: $cl_black;

// Typography 
$font-weight-bold: 800;
$font-weight-medium: 500;
$font-weight-light: 200;
$font-weight-normal: normal;
$font-name-proxima: 'Proxima Nova';

//Bootstrap: Override any variables as needed
$enable-flex: true;
$font-family-base: $font-name-proxima, sans-serif;
$font-size-base: 1rem;
$font-weight-base: $font-weight-light;
$line-height-base: 1.2;
$font-size-root: medium;
$body-color: $cl_on-background;
$body-bg: $cl_background;
$link-color: $cl_primary;
$link-decoration: underline;
$link-hover-color: inherit;
$link-hover-decoration: underline;
$headings-margin-bottom: 1rem;
$paragraph-margin-bottom: 1rem;
$label-margin-bottom: 1rem;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px
) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1380px
) !default;
