.page {
    padding-top: 50px;
    @include media-breakpoint-up(md) {
        padding-top: 77px;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $cl_background;
    border-bottom: solid 1px $cl_light_gray;
    z-index: 1;
    .container {
        padding-top: 12px;
        padding-bottom: 13px;
        @include media-breakpoint-up(md) {
            padding-top: 19px;
            padding-bottom: 22px;
            overflow: hidden;
        }
    }
    .logo {
        display: block;
        background: url(/img/logo.png) no-repeat;
        background-size: contain;
        width: 140px;
        height: 30px;
        margin: 0 auto -6px;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            float: left;
            background-image: url(/img/logo-desktop.png);
            width: 164px;
            height: 35px;
            margin: 0;
        }
        h1 {
            color: transparent;
        }
    }
    nav {
        text-transform: uppercase;
        position: absolute;
        top: 13px;
        left: 20px;
        @include media-breakpoint-up(md) {
            position: inherit;
            top: 0;
            left: 0;
            float: right;
        }
        .menu {
            display: block;
            color: transparent;
            overflow: hidden;
            width: 24px;
            height: 24px;
            background: url(/img/icons/menu.png) no-repeat;
            background-size: contain;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
        .menu-items {
            padding: 2rem 1rem;
            position: fixed;
            top: 0;
            left: 0;
            background: $cl_surface;
            height: 100vh;
            width: 50vw;
            z-index: 100;
            transform: translateX(-75vw);
            transition: transform ease-in-out 250ms;
            border-right: solid 1px $cl_light_gray;
            box-shadow: 0 0 25vw rgba(0,0,0,0.25);
            @include media-breakpoint-up(md) {
                padding: 0.5rem 0 0;
                position: inherit;
                background: none;
                height: auto;
                width: auto;
                z-index: inherit;
                transform: none;
                border: 0;
                box-shadow: none;
            }
        }
        &.open {
            .menu-items {
                transform: translateX(0);
            }
        }
        ul {
            list-style-type: none;
            margin: 0;
        }
        li {
            margin: 0 0 2rem;
            @include media-breakpoint-up(md) {
                display: inline-block;
                margin: 0 0 0 44px;
            }
        }
        a {
            color: $cl_black;
            &.cta {
                padding: 5px 16px 6px;
                margin: -5px 0 -6px;
            }
        }
    }
}