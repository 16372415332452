.subscribe {
    .h2 {
        margin-bottom: 1.5rem;
    }
    fieldset {
        margin-bottom: 0.25rem;
    }
    @include media-breakpoint-down(md) {
        .col {
            min-width: 100%;
        }
    }
}