.cta {
    &.primary {
        background: $cl_primary;
        color: $cl_on-primary;
        padding: 0.8rem 0 0.7rem;
        border-radius: 0.1rem;
        width: 100%;
        text-align: center;
    }
    &.secondary {
        border: solid 0.075rem $cl_on-background;
        border-radius: 0.1rem;
        color: $cl_on-background;
        padding: 0.8rem 1rem;
        text-align: center;
    }
}