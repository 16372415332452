.page.index {
    #main-content {
        position: relative;
        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
        }
    }
    .illustration {
        position: relative;
        padding-top: 50px;
        padding-bottom: 20px;
        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
        img {
            margin: 0 auto;
            max-width: 100%;
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    .hero {
        form {
            margin: 2.5rem 0;
            @include media-breakpoint-up(lg) {
                max-width: 307px;
            }
        }
    }
    #business-code .illustration.mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .h-card {
        .p-name {
            margin-bottom: 0.5rem;
        }
        .u-url,
        .h-adr {
            display: block;
            margin: 0 0 0.5rem;
        }
        .h-adr {
            color: $cl_on-background;
            background: url(/img/icons/place.svg) no-repeat top left;
            background-size: 0.75rem 1rem;
            padding-left: 1rem;
        }
        .illustration {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
                padding-left: 6%;
            }
            img {
                position: relative;
                width: 100%;
            }
        }
    }
    .message {
        margin: 1rem 0 0;
        img {
            max-width: 480px;
            width: 100%;
            margin: 0.5rem 0;
        }
    }
    .social {
        margin: 2.5rem 0 0;
        .twitter-tweet {
            background: $cl_surface;
            padding: 1.5rem;
            border-radius: 0.1rem;
        }
        .youtube-player {
            position: relative;
            width: 100%;
            padding-top: 56.25%;
            #ytplayer {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .post-selfie {
        margin-top: 4.5rem;
        background: $cl_surface;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        @include media-breakpoint-up(lg) {
            padding-bottom: 7rem;
        }
        .illustration {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 2.5rem;
            text-align: center;
            @include media-breakpoint-up(lg) {
                img {
                    left: 10%;
                    right: auto;
                    max-width: 257px;
                }
            }
        }
        .content {
            @include media-breakpoint-up(lg) {
                padding-top: 3rem;
                padding-left: 50%;
                .h2 {
                    max-width: 23.25rem;
                }
                .cta {
                    max-width: 15.35rem;
                }
            }
        }
        .cta {
            display: block;
            margin-top: 0.5rem;
            width: 100%;
            &:before {
                content: url(/img/icons/instagram.svg);
                display: inline-block;
                width: 0.95rem;
                height: 0.85rem;
                margin-top: -0.1rem;
                margin-right: 0.45rem;
                vertical-align: middle;
            }
        }
    }
    article {
        background-color: $cl_background;
    }
    .subscribe-container {
        background: $cl_surface;
        padding-top: 2.5rem;
        padding-bottom: 3rem;
    }
    #no-code {
        padding-bottom: 2.5rem;
        @include media-breakpoint-up(lg) {
            padding-bottom: 3.75rem;
        }
    }
    #business-code,
    #no-code,
    .subscribe-container {
        opacity: 0;
        transition: opacity ease-in 250ms;
    }
    .loaded #no-code,
    .loaded .subscribe-container {
        opacity: 1;
    }
    #business-code {
        display: none;
        opacity: 0;
        transition: opacity ease-in 250ms;
    }
    .has-business {
        #business-code {
            display: block;
        }
        &.loaded {
            #no-code {
                width: 100%;
                opacity: 0;
                position: absolute;
            }
            #business-code {
                opacity: 1;
            }
            .subscribe-container {
                background-color: $cl_background;
            }
        }
    }
}