#main-content {
    background: $cl_background;
    min-height: calc(100vh - 2.8rem);
}

.footer {
    background: $cl_on-background;
    color: $cl_background;
    overflow: hidden;
    .container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .legal {
        margin-bottom: 0.75rem;
        @include media-breakpoint-up(lg) {
            float: left;
        }
    }
    nav {
        @include media-breakpoint-up(lg) {
            float: right;
        }
    }
    ul {
        padding: 0;
        list-style-type: none;
        display: block;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }
    li {
        padding: 0;
        display: inline-block;
        margin-right: 0.5rem;
        @include media-breakpoint-up(lg) {
            margin-left: 2rem;
            margin-right: 0;
        }
    }
    a {
        color: $cl_background;
    }
}