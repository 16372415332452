@font-face {
  font-family: $font-name-proxima;
  font-weight: $font-weight-bold;
  src: url('../fonts/_MI_Proxima-Nova-Bold.otf') format('truetype');
}
@font-face {
  font-family: $font-name-proxima;
  font-weight: $font-weight-medium;
  src: url('../fonts/_MI_Proxima-Nova-Semibold.otf') format('truetype');
}
@font-face {
  font-family: $font-name-proxima;
  font-weight: $font-weight-light;
  src: url('../fonts/_MI_Proxima-Nova-Regular.otf') format('truetype');
}

html {
  font-family: $font-name-proxima;
  font-size: 20px;
  color: $cl_on-background;
}

.h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
  }
}

.h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

a {
  color: $cl_primary;
}
a,
a:hover {
    text-decoration: none;
}

.header {
  font-size: 0.8rem;
}

input {
  font-weight: $font-weight-medium;
  &::placeholder {
    color: inherit;
  }
}

label,
.error-message {
  font-size: 0.6rem;
  font-weight: $font-weight-medium;
}

.error-message {
  color: $cl_error;
}

.has-error {
  label,
  .error-message {
    color: $cl_error;
  }
}

.cta {
  font-size: 0.8rem;
  &.primary {
    font-weight: $font-weight-medium;
  }
  &.secondary {
    font-weight: $font-weight-medium;
  }
}

.u-url,
.h-adr {
  font-size: 0.8rem;
}

.footer {
  font-size: 0.7rem;
}

strong {
  font-weight: $font-weight-bold;
}

// mobile a - purple (9521e3)

// mobile h1 - 30px bold
// desktop h1 - 48px bold

// h2 - 30px bold

// body - 20px

// form - semibold, 20px

// form error - 12px semibold red (ff3527)

// mobile cta button - bold, 16px, white; 16 t/b fill width
// desktop cta button - "; 128-16-128-16

// primary cta - semibold, 16px, purple (9521e3)

// secondary cta - bold, 16px black

// header - 16px all caps

// footer - 14px, white

// address - 16px

// key point - 20px semibold

// supporting point - 16px

// how it works point - 20px