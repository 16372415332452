.carousel {
    position: relative;
    .carousel-items {
        position: relative;
    }
    .carousel-item {
        background: $cl_background;
        @include media-breakpoint-down(md) {
            opacity: 0;
            transition: opacity 200ms ease-in;
            position: absolute;
            top: 0;
            left: 0;
            &.active {
                opacity: 1;
                position: relative;
            }
        }
    }
    .carousel-controls {
        display: none;
        position: absolute;
        top: 0.3rem;
        right: 1.5rem;
        @include media-breakpoint-down(md) {
            display: block;
        }
        a {
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            overflow: hidden;
            color: transparent;
            background: none no-repeat center center;
            &:first-child {
                background-image: url(/img/icons/left.svg);
            }
            &:last-child {
                margin-left: 1.25rem;
                background-image: url(/img/icons/right.svg);
            }
        }
        &.at-start a:first-child,
        &.at-end a:last-child {
            opacity: 0.25;
        }
    }
}